<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ !!this.userSelectedChurchKey ? translations.tcEditChurch : translations.tcAddChurch }}</h1>
      <b-form validated>
        <b-form-group v-if="verb === 'Add'">
          <b-form-radio-group id="btn-radios-2" v-model="add_type" :options="add_options" buttons size="sm"
            button-variant="outline-primary" name="radio-btn-outline"></b-form-radio-group>
        </b-form-group>
        <br />
        <section class="section-3 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcLocation }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcToolTipLocation">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
            </header>
            <div v-if="Section1">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label" for="name-input">
                          {{ translations.tcChurchName }}
                        </div>
                        <b-form-input :title="translations.tcChurchName" id="name-input" class="g-select flex-0 mr-3 mb-3"
                          :class="{ 'is-invalid': !church.church_name && formSubmitted }" v-model="church.church_name"
                          required ref="churchNameRef"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="verb === 'Add'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label" for="address1-input">
                          {{ translations.tcAddress1 }}
                        </div>
                        <b-form-input :title="translations.tcAddress1" id="address1-input"
                          class="g-select flex-0 mr-3 mb-3" v-model="church.address_line_1"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="verb === 'Add'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label" for="address2-input">
                          {{ translations.tcAddress2 }}
                        </div>
                        <b-form-input :title="translations.tcAddress2" id="address2-input"
                          class="g-select flex-0 mr-3 mb-3" v-model="church.address_line_2"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="verb === 'Add'">
                    <b-col sm="4" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label" for="city-input">
                          {{ translations.tcCity }}
                        </div>
                        <b-form-input :title="translations.tcCity" id="city-input" v-model="church.city"
                          class="g-select flex-0 mr-3 mb-3"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="5" class="font-style-3">
                      <div class="form_label" for="state-select">
                        {{ translations.tcState }}
                      </div>
                      <b-form-select :title="translations.tcState" id="state-select" v-if="!nonUS"
                        v-model="church.church_state_key" :options="optionsStates" @change="handleStateChange($event)"
                        class="g-select flex-0 mr-3 mb-3"></b-form-select>
                      <b-form-input v-if="nonUS" :title="translations.tcState" id="state-input"
                        v-model="church.church_state" class="g-select flex-0 mr-3 mb-3"></b-form-input>
                    </b-col>
                    <b-col sm="3" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label" for="zip-input">
                          {{ translations.tcPostalCode }}
                        </div>
                        <b-form-input :title="translations.tcPostalCode" id="zip-input" class="g-select flex-0 mr-3 mb-3"
                          v-model="church.postal_code"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="verb === 'Add'">
                    <b-col sm="12" class="font-style-3">
                      <div class="form_label" for="country-select">{{ translations.tcCountry }}</div>
                      <b-form-select id="country-select" :title="translations.tcCountry"
                        v-model="church.church_country_key" :options="optionsCountries"
                        class="g-select flex-0 mr-3 mb-3"></b-form-select>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6" v-show="add_type === 1">
                  <div class="form_label" for="church-image">
                    {{ translations.tcChurchImage }}
                  </div>
                  <b-form-file id="church-image" style="text-transform: uppercase" ref="fileUploadImg"
                    v-model.lazy="church_image_file" name="church_image" :placeholder="translations.tcNoFileChosen"
                    :browse-text="translations.tcBrowse" :drop-placeholder="translations.tcDropFileHere" />
                  <div style="margin-top: 30px">
                    <img v-if="!!church.church_image && !church.church_image.includes('default')"
                      :src="church.church_image" class="church_image" height="185" width="285" alt />
                    <camera
                      v-if="!church.church_image || (!!church.church_image && church.church_image.includes('default'))">
                    </camera>
                  </div>
                  <div class="remove_button" v-if="!!church.church_image && !church.church_image.includes('default')">
                    <b-button @click="handleRemoveImageClick()">{{ translations.tcRemoveImage }}</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-show="add_type === 1 && verb === 'Add'">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcCommunications }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcToolTipCommunications">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
            </header>
            <div v-if="Section3">
              <b-row>
                <b-col sm="6" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label">
                      {{ translations.tcPhone }}
                    </div>
                    <b-form-input id="phone-input" :title="translations.tcPhone" v-model="church.phone_number"
                      class="g-select flex-0 mr-3 mb-3"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label">
                      {{ translations.tcEmailAddress }}
                    </div>
                    <b-form-input id="email-input" :title="translations.tcEmailAddress" class="g-select flex-0 mr-3 mb-3"
                      v-model="church.email"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-show="add_type === 1">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcChurchDetails }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcToolTipChurchDetails">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section4 = !Section4" section="Section4" :status="Section4"></ToggleArrow>
            </header>
            <div v-if="Section4">
              <b-row>
                <b-col sm="2" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label" for="assignment-input">
                      {{ translations.tcAssignmentNumber }}
                    </div>
                    <b-form-input id="assignment-input" :title="translations.tcAssignmentNumber"
                      v-model="church.camp_assigned_church_number" class="g-select flex-0 mr-3 mb-3"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="4" class="font-style-3">
                  <div class="form_label" for="denomination-select">
                    {{ translations.tcDenomination }}
                  </div>
                  <b-form-select id="denomination-select" v-model="church.org_den_key" :options="optionsDenominations"
                    class="g-select flex-0 mr-3 mb-3">
                    <template slot="first">
                      <option :value="null">
                        Select one
                      </option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col sm="2" class="font-style-3">
                  <div class="form_label" for="rank-select">
                    {{ translations.tcRank }}
                  </div>
                  <b-form-select id="rank-select" v-model="church.chu_crt_key" :title="translations.tcRank"
                    :options="optionsRank" class="g-select flex-0 mr-3 mb-3">
                  </b-form-select>
                </b-col>
                <b-col sm="4" class="font-style-3">
                  <div class="form_label" for="support-select">
                    {{ translations.tcSupportLevel }}
                  </div>
                  <b-form-select id="support-select" :title="translations.tcSupportLevel" v-model="church.chu_chl_key"
                    :options="optionsSupportTranslated" class="g-select flex-0 mr-3 mb-3">
                    <template slot="first">
                      <option :value="null">
                        Select one
                      </option>
                    </template>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3" class="font-style-3">
                  <div class="form_label" for="language-select">
                    {{ translations.tcLanguage }}
                  </div>
                  <b-form-select id="language-select" :title="translations.tcLanguage" v-model="church.chu_lnt_key"
                    :options="churchLanguagesTranslated" class="g-select flex-0 mr-3 mb-3">
                    <template slot="first">
                      <option :value="null">
                        Select one
                      </option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col sm="3" class="font-style-3">
                  <div class="form_label" for="relationship-select">
                    {{ translations.tcRelationshipStrength }}
                  </div>
                  <b-form-select id="relationship-select" :title="translations.tcRelationshipStrength"
                    v-model="church.chu_chr_key" :options="optionsRelationshipStrengthTranslated"
                    class="g-select flex-0 mr-3 mb-3">
                    <template slot="first">
                      <option :value="null">
                        Select one
                      </option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col sm="4" class="font-style-3">
                  <div class="form_label" for="rep-select">
                    {{ translations.tcGideonsRepresentative }}
                  </div>
                  <b-form-select id="rep-select" :title="translations.tcGideonsRepresentative"
                    v-model="church.gideons_church_rep_key" :options="optionsGideonsRep"
                    class="g-select flex-0 mr-3 mb-3">
                  </b-form-select>
                </b-col>
                <b-col sm="2" class="font-style-3">
                  <div class="form_label" for="gideoncard-displays">
                    {{ translations.tcGideonCardDisplays }}
                  </div>
                  <b-form-select id="gideoncard-displays" :title="translations.tcGideonCardDisplays"
                    v-model="church.gideoncard_display_number" :options="rackOptions" class="g-select flex-0 mr-3 mb-3">
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label" for="attendance-input">
                      {{ translations.tcEstimatedAttendance }}
                    </div>
                    <b-form-input :title="translations.tcEstimatedAttendance" id="attendance-input"
                      v-model="church.estimated_worship_attendance" class="g-select flex-0 mr-3 mb-3"
                      @change="formatEstimatedWorshipAttendance"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label" for="url-input">
                      {{ translations.tcChurchWebsiteAddress }}
                    </div>
                    <b-form-input id="url-input" :title="translations.tcChurchWebsiteAddress" v-model="church.church_url"
                      class="g-select flex-0 mr-3 mb-3"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row style="margin-top: 20px">
                <b-col sm="3" class="font-style-3">
                  <b-form-checkbox id="checkbox-mega" v-model="church.mega_church_flag" name="checkbox-mega" value="true"
                    class="mb-3" unchecked-value="false"></b-form-checkbox>&nbsp;
                  <label for="checkbox-mega">
                    <h3 class="font-style-2 check_label">
                      {{ translations.tcMegaChurch }}
                    </h3>
                  </label>
                </b-col>
                <b-col sm="3" class="font-style-3">
                  <b-form-checkbox id="checkbox-broadcast" v-model="church.radio_television_broadcast_flag"
                    name="checkbox-broadcast" value="true" class="mb-3" unchecked-value="false"></b-form-checkbox>&nbsp;
                  <label for="checkbox-broadcast">
                    <h3 class="font-style-2 check_label">
                      {{ translations.tcRadioTVBroadcast }}
                    </h3>
                  </label>
                </b-col>
                <b-col sm="3" class="font-style-3">
                  <b-form-checkbox id="checkbox-bivocational" v-model="church.pastor_bi_vocational_flag"
                    name="checkbox-bivocational" value="true" class="mb-3" unchecked-value="false"></b-form-checkbox>
                  &nbsp;
                  <label for="checkbox-bivocational">
                    <h3 class="font-style-2 check_label">
                      {{ translations.tcPastorBiVocational }}
                    </h3>
                  </label>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3" class="font-style-3">
                  <b-form-checkbox id="checkbox-lifebook" v-model="church.life_book" name="checkbox-lifebook" value="true"
                    class="mb-3" unchecked-value="false"></b-form-checkbox>&nbsp;
                  <label for="checkbox-lifebook">
                    <h3 class="font-style-2 check_label">
                      {{ translations.tcLifeBook }}
                    </h3>
                  </label>
                </b-col>
                <b-col sm="3" class="font-style-3">
                  <b-form-checkbox id="checkbox-inchurchbudget" v-model="church.in_church_budget"
                    name="checkbox-inchurchbudget" value="true" class="mb-3" unchecked-value="false"></b-form-checkbox>
                  &nbsp;
                  <label for="checkbox-inchurchbudget">
                    <h3 class="font-style-2 check_label">
                      {{ translations.tcInChurchBudget }}
                    </h3>
                  </label>
                </b-col>
                <b-col sm="3" class="font-style-3">
                  <b-form-checkbox id="checkbox-showchurch" v-model="church.show_online_flag" name="checkbox-showchurch"
                    value="true" class="mb-3" unchecked-value="false"></b-form-checkbox>&nbsp;
                  <label for="checkbox-showchurch">
                    <h3 class="font-style-2 check_label">{{ translations.tcShowOnline }}</h3>
                  </label>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label" for="note-textarea">
                      {{ translations.tcOtherInformation }}
                    </div>
                    <b-form-textarea id="note-textarea" v-model="church.note" :title="translations.tcOtherInformation"
                      rows="5" max-rows="8"></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button v-if="first_time" variant="primary" @click="handleSaveClick(1)"
                class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcSaveAndAddContactPerson }}
              </b-button>
              <b-button variant="primary" @click="handleSaveClick(0)" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcSave }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import camera from '@/assets/svgs/camera.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-church',
  mixins: [numericMixin, translationMixin],
  data() {
    return {
      add_type: 0,
      church: { show_online_flag: true, note: null },
      churchLanguages: [
        { value: 'b468f566-42a9-4b07-a5a0-f2ac4afd1a36', text: 'Chinese' },
        { value: 'c3964f7d-1307-48b9-9f60-dae9388b5478', text: 'English' },
        { value: 'dfedf829-347b-4959-9d6b-a072529c4cc8', text: 'French' },
        { value: '1da6e0c6-a66d-4216-adc1-7816e0ab845c', text: 'German' },
        { value: '36c1a24f-21f3-43bb-97e3-630390b75d43', text: 'Japanese' },
        { value: 'a1737579-fe30-40c2-ad3e-ff880ab79646', text: 'Korean' },
        { value: 'ff8d6bae-8f65-4aaa-be74-506ffac2241a', text: 'Other' },
        { value: '5bee967a-1425-4d08-9ca2-bca08217b3b4', text: 'Russian' },
        { value: '8a2beb3f-a979-4314-9e5f-702c3e25c3bd', text: 'Spanish' },
      ],
      churchLanguagesTranslated: {},
      church_image_file: null,
      findButton: '',
      first_time: true,
      formSubmitted: false,
      optionsRelationshipStrengthTranslated: {},
      optionsSupportTranslated: {},
      pastor_checkbox_tooltip: 'The pastor & spouse information is optional.',
      pastor_exists: false,
      pastor_primary_flag: true,
      presetDate: null,
      rackOptions: Array.from(Array(16), (_, x) => ({ text: x, value: x })),
      searchedContact: [],
      Section1: true,
      Section2: true,
      Section3: true,
      Section4: true,
      selectChurch: [],
      statusKey: null,
      translations: {},
    }
  },
  async created() {
    await this.page_load()
  },
  methods: {
    ...mapActions({
      addChurch: 'churchMinistry/addChurch',
      clearChurchDetail: 'churchMinistry/clearChurchDetail',
      deleteChurchImage: 'churchMinistry/deleteChurchImage',
      getElectionGideonMembers: 'election/getElectionGideonMembers',
      getSelectionListsForChurchAdd: 'churchMinistry/getSelectionListsForChurchAdd',
      loadPresenters: 'churchMinistry/loadPresenters',
      setActiveChurchContactType: 'churchMinistry/setActiveChurchContactType',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedChurchContact: 'user/setSelectedChurchContact',
    }),
    formatEstimatedWorshipAttendance() {
      this.church.estimated_worship_attendance = this.church.estimated_worship_attendance.replace(/[^0-9]/g, '')
    },
    handlePastorButtonClick() {
      this.pastor_exists = !this.pastor_exists
    },
    handleStateChange(evt) {
      this.church.church_state = this.optionsStates.find((ch) => ch.value === evt).code
    },
    async handleCancelClick() {
      let goto = this.$router.currentRoute.path.includes('edit-church')
        ? '/programs/cm/church-details'
        : '/programs/cm/church-list'
      this.$router.push(goto)
    },
    async handleRemoveImageClick() {
      let good_delete = true
      await Promise.all([
        this.setLoadingStatus(true),
        await (good_delete = this.deleteChurchImage(this.detail.church_image_key)),
        (this.church.church_image = null),
        (this.church.church_image_key = null),
        (this.church_image_file = null),
      ]).then(() => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: good_delete ? 'success' : 'error',
          text: good_delete ? this.translations.tcImageDeleted : this.translations.tcImageDeleteFailed,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      })
    },
    async handleSaveClick(sw) {
      let result = null
      if (this.validateForm()) {
        await (this.church.church_image = this.church_image_file)
        await (this.church.note = !!this.church.note ? this.church.note.replaceAll('"', "'").replaceAll('<', '[').replaceAll('>', ']') : '')
        await Promise.all([
          this.setLoadingStatus(true),
          await this.setActiveChurchContactType(0),
          await this.setSelectedChurchContact({}),
          (result = await this.addChurch(this.church)),
        ]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: !result
              ? this.translations.tcErrorGeneral || 'There was an error.'
              : !!this.church.chu_org_key
                ? this.translations.tcChurchEdited || 'The church was successfully edited.'
                : this.translations.tcChurchAdded || 'The church was added.',
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((response) => {
            if (result) {
              this.church = this.empty_church
              if (this.$router.currentRoute.path.includes('edit-church')) {
                this.$router.push('/programs/cm/church-details')
              } else {
                let push_path =
                  sw === 0 ? '/programs/cm/church-list' : '/programs/cm/churchProfile/add-church-contact/0'
                this.$router.push({ path: push_path })
              }
            }
          })
        })
      }
    },
    async getChurchForEdit() {
      if (!!this.userSelectedChurchKey && this.isGuid(this.userSelectedChurchKey)) {
        this.church = this.detail
        this.first_time = false
        this.add_type = 1
      }
    },
    async applyFoundPerson(evt) {
      this.searchedContact.push({ value: evt.ind_key, text: evt.name_text })
      this.selectedContactKey = evt.ind_key
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    validateForm() {
      this.formSubmitted = true
      let rtn = true
      if (!this.church.church_name) {
        this.$refs.churchNameRef.focus()
        rtn = false
        this.$swal({
          icon: 'error',
          text:
            this.translations.tcErrorChurchNameRequired ||
            'The church name is required to save a church. Please complete the fields bordered in red.',
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
      return rtn
    },
    async resetChurch() {
      await (async () => {
        this.church = JSON.parse(JSON.stringify(this.empty_church))
        if (this.verb == 'Add' && this.countryFromOfficerToolbar && this.countryFromOfficerToolbar.value) {
          this.church.church_country_key = this.countryFromOfficerToolbar.value
        } else {
          this.church.church_country_key = this.userCountryObject.key
        }
      })()
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getTranslations(),
          await this.getComponentTranslations('common.church-languages', 'common.relationship', 'common.support-level'),
          await this.clearChurchDetail(),
          await this.resetChurch(),
          await this.getElectionGideonMembers(),
          await this.getSelectionListsForChurchAdd(),
          await this.getChurchForEdit(),
        ]).then((results) => {
          this.stripReadableText(results[1])
          this.preselectCountryFromOfficerToolbar()
          this.translateDropdownOptions([
            { node: 'church-languages', store: 'churchLanguages' },
            { node: 'relationship', store: 'optionsRelationshipStrength' },
            { node: 'support-level', store: 'optionsSupport' },
          ])
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    preselectCountryFromOfficerToolbar() {
      if (this.verb !== 'Add') return
      this.church.church_country_key = this.countryFromOfficerToolbar.value
    },
  },
  computed: {
    ...mapGetters({
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      detail: 'churchMinistry/churchDetailSelect',
      empty_church: 'churchMinistry/empty_church',
      gideonMembersList: 'election/gideonMembersList',
      officerToolbarSelected: 'user/officerToolbarSelected',
      optionsDenominations: 'churchMinistry/optionsDenominations',
      optionsLanguages: 'churchMinistry/optionsLanguages',
      optionsRank: 'churchMinistry/optionsRank',
      optionsRelationshipStrength: 'churchMinistry/optionsRelationshipStrength',
      optionsSupport: 'churchMinistry/optionsSupport',
      prefCulture: 'user/userPreferredCulture',
      userCountryObject: 'user/userCountryObject',
      userId: 'user/userId',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
    }),
    countryFromOfficerToolbar() {
      const [country] = this.convertedAddressFormOptions.countriesByCamp
      return country
    },
    verb() {
      return !!this.userSelectedChurchKey ? 'Edit' : 'Add'
    },
    add_options() {
      return !!this.userSelectedChurchKey
        ? [
          { value: 0, text: `${this.translations.tcQuickEdit}` },
          { value: 1, text: `${this.translations.tcFullEdit}` },
        ]
        : [
          { value: 0, text: `${this.translations.tcQuickAdd}` },
          { value: 1, text: `${this.translations.tcFullAdd}` },
        ]
    },
    optionsStates() {
      return [
        {
          value: null,
          text: this.translations.tcState || 'State',
          disabled: true,
        },
        ...this.convertedAddressFormOptions.states_long,
      ]
    },
    optionsCountries() {
      return [
        {
          value: null,
          text: this.translations.tcSelectACountry || 'Select a country',
          disabled: true,
        },
        ...this.convertedAddressFormOptions.countries,
      ]
    },
    optionsGideonsRep() {
      return [
        {
          value: null,
          text: this.translations.tcNoRepresentative || 'No Representative',
        },
        ...this.gideonMembersList.filter((x) => x.value !== 'Unfilled'),
      ]
    },
    nonUS() {
      return this.church.church_country_key !== 'bc4b70f8-280e-4bb0-b935-9f728c50e183'
    },
  },
  components: {
    camera: camera,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.form-group {
  margin-bottom: 0 !important;
}

.form_label {
  font-size: 14px !important;
  font-weight: 600;
}

.church_image {
  background-size: 100% 100%;
}

.remove_button {
  margin-top: 30px;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
